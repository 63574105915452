.container{
    background-color: black;
    padding-top: 50px;
    padding-bottom: 50px;
}
.title{
    font-family: 'BootsHaus-Regular';
    color: white;
    font-weight: 900;
    font-size: 3.8rem;
    text-align: center;
    margin-bottom: 10px;
}
.main{
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 0 auto 50px auto;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 95%;
    min-height: 70vh;
    border-radius: 30px;
    background-color: #9f1312;
    /* background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(159, 19, 18, 0.5) 0%, #9f1312 15%, #9f1312 85%, rgba(159, 19, 18, 0.5) 100%); */
}
.dividerContainer{
    position: absolute;
    height: 600px;
    width: 110px;
}
.divider{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.descContainer{
    box-shadow:
              0 0 1rem rgba(255, 255, 255, 0.4),
              0 0 0.4rem rgba(255, 255, 255, 0.4),
              0 0 1.2rem rgba(255, 255, 255, 0.4);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 600px;
    width: 50%;
    padding: 30px;
}
.desc{
    font-family: 'Oswald', 'sans-serif';
    color: white;
    font-weight: 600;
    font-size: 1.35rem;
    text-align: center;
}
.imgContainer{
    max-height: 600px;
    max-width: 650px;
    width: 50%;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.btnContainer{
    display: flex;
    justify-content: center;
}
.order{ 
    font-family: 'BootsHaus-Regular';
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 20px;
    padding: 5px 30px 0px 30px;
    background-color: #9f1312;
    border: 2px solid #9f1312;
    letter-spacing: 2px;
    color: white;
    transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
}
.order:hover{
    cursor: pointer;   
    background-color: black;
    color: #9f1312;
    box-shadow:
              0 0 1rem rgba(159, 19, 18, 0.4),
              0 0 0.4rem rgba(159, 19, 18, 0.4),
              0 0 1.2rem rgba(159, 19, 18, 0.4);
}

@media(max-width: 1570px){
    .main{
        width: 95%;
    }
    .desc{
        font-size: 1.25rem
    }
    .title{
        font-size: 3rem;
    }
}

@media(max-width: 1370px){
    .main{
        width: 95%;
    }
    .desc{
        font-size: 1.1rem
    }
    .title{
        font-size: 2.75rem;
    }
}
@media(max-width: 1040px){
    .dividerContainer{
        display: none;
    }
    .main{
        flex-direction: column;
        align-items: center;
    }
    .descContainer{
        width: 100%;
        max-height: none;
    }
    .imgContainer{
        width: 100%;
        max-height: none;
    }
    .desc{
        font-size: 1.5rem
    }
}