
.container{
    background-color: #e8ffc7;
    padding-top: 50px;
}
.title{
    font-family: 'Comfortaa', 'sans-serif';
    color: #008037;
    font-weight: 900;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 40px;
}
.about{
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 0 auto 50px auto;
    align-items: center;
    justify-content: center;
    width: 90%;
    min-height: 70vh;
    border-radius: 30px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(127, 219, 87, 1) 0%, rgba(232,255,199,1) 30%, rgba(232,255,199,1) 60%, rgba(127, 219, 87, 1) 100%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.dividerContainer{
    position: absolute;
    height: 600px;
    width: 110px;
}
.divider{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.aboutDescContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 600px;
    width: 50%;
    padding: 30px;
}
.aboutDesc{
    font-family: 'Comfortaa', 'sans-serif';
    color: black;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
}
.aboutImgContainer{
    max-height: 600px;
    max-width: 750px;
    width: 50%;
}
.aboutImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.btnContainer{
    display: flex;
    justify-content: center;
}
.order{ 
    font-family: 'Comfortaa', sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    background-color: #008037;
    border: 2px solid #008037;
    border-radius: 20px;
    padding: 5px 30px;
    color: black;
    transition: background-color 0.4s, color 0.4s,
}
.order:hover{
    cursor: pointer;   
    background-color: black;
    color: #008037;
}

@media(max-width: 1570px){
    .about{
        width: 95%;
    }
    .aboutDesc{
        font-size: 1.3rem
    }
    .title{
        font-size: 3rem;
    }
}

@media(max-width: 1370px){
    .about{
        width: 95%;
    }
    .aboutDesc{
        font-size: 1.1rem
    }
    .title{
        font-size: 2.5rem;
    }
}
@media(max-width: 860px){
    .dividerContainer{
        display: none;
    }
    .about{
        flex-direction: column;
        align-items: center;
    }
    .aboutDescContainer{
        width: 100%;
        max-height: none;
    }
    .aboutImgContainer{
        width: 100%;
        max-height: none;
    }
    .aboutDesc{
        font-size: 1.5rem
    }
}