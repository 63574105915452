
.OrderSection{
    background-color: #e8ffc7;
    padding: 2rem;
    font-family: 'Comfortaa', sans-serif;
}

.order{
    width: 60%;
    margin: 40px auto;
}

.txtEtc{
    color: black;
}

.title{
    color: #008037;
    font-family: 'Comfortaa', 'sans-serif';
    text-align: center;
    font-size: 4rem;
}

.tuple{
    display: flex;
    justify-content: space-between;
}

.part{
    display: flex;
    flex-direction: column;
    width: 48%;
}

.createSubmitBtn{
    all: unset;
    font-size: 1.8rem;
    font-weight: 500;
    background-color: #008037;
    color: black;
    border-radius: 10px;  
    padding: 4px 20px;
    transition: background-color 0.4s, color 0.4s,
}

.createSubmitBtn:hover{
    background-color: black;
    color: #008037;
}

.input{
    border: 2px solid #008037;
}

.label{
    color: #008037;
}

@media(max-width: 1200px){
    .order{
        width: 80%;
    }
}

@media(max-width: 768px){
    .order{
        width: 100%;
    }
}

@media(max-width: 730px){
    .tuple{
        display: block;
    }
    .part{
        display: block;
        width: 100%;
    }
}
