.container{
    padding: 50px 0;
    background-color: black;
    padding: 30px 0 50px 0;
}
.title{
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 3.5rem;
    text-align: center;
}
.aboutContainer{
    padding: 30px;
    border: 2px solid #f8b11a;
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
    box-shadow:
              0 0 1rem rgba(248, 177, 26, 0.6),
              0 0 0.4rem rgba(248, 177, 26, 0.6),
              0 0 1.2rem rgba(248, 177, 26, 0.6);
}
.about{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    align-items: center;
}
.aboutDescContainer{
    width: 40%;
}
.aboutDesc{
    font-family: 'Oswald', 'sans-serif';
    color: white;
    font-size: 1.8rem;
    text-align: center;
}
.aboutImgContainer{
    /* box-shadow:
              0 0 1rem rgba(248, 177, 26, 0.3),
              0 0 0.4rem rgba(248, 177, 26, 0.3),
              0 0 1.2rem rgba(248, 177, 26, 0.3); */
    width: 480px;
    height: 750px;
    border-radius: 20px;
    overflow: hidden;
}
.aboutImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.btnContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}
.order{ 
    font-family: 'Oswald', sans-serif;
    font-size: 1.6rem;
    text-decoration: none;
    background-color: #212529;
    border: 2px solid #212529;
    border-radius: 20px;
    padding: 5px 30px;
    color: #f8b11a;
    transition: background-color 0.4s, color 0.4s,
}
.order:hover{
    cursor: pointer;   
    background-color: #f8b11a;
    color: #212529;
}

@media(max-width: 1125px){
    .about{
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }
    .aboutDescContainer{
        width: 100%;
    }
    .aboutImgContainer{
        width: 100%;
        height: 650px;
    }
}
@media (max-width: 595px) {
    .aboutContainer{
        width: 100%;
    }
    .aboutImgContainer{
        width: 100%;
        height: 450px;
    }
    .title{
        font-size: 3rem;
    }
    .aboutDesc{
        font-size: 1.5rem;
    }
}