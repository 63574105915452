
.personalInfoRules{
    color: white;
    background-color: black;
    min-height: 72.6vh; 
}
.container{
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px 30px;
}
.title{
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 3.5rem;
    margin-bottom: 40px;
}
.subtitle{
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 2.5rem;
    margin-bottom: 10px;
}
.rule{
    font-family: "Oswald", "sans-serif";
    font-size: 1.25rem;
}
.link{
    text-decoration: none;
    color: #f8b11a;
}
.link:hover{
    text-decoration: underline;
}