.main{
    width: 100%;
    min-height: 800px;
    /* background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(232,255,199,1) 0%, rgba(127, 219, 87, 1) 30%, rgba(127, 219, 87, 1) 60%, rgba(232,255,199,1) 100%); */
    background: rgb(0,0,0);
    background: linear-gradient(150deg, rgba(232,255,199,1) 0%, rgba(127, 219, 87, 1) 50%, rgba(232,255,199,1) 100%); 
    padding: 50px 5px;
    border-top: 2px solid #008037;
    border-bottom: 2px solid #008037;
}
.subContainer{
    display: flex;
    height: 70%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.upperBtn{
    padding: 3px 10px;
    font-size: 1.2rem;
    border-radius: 15px;
    background-color: #45a970;
    border: 1px solid transparent; 
    transition: box-shadow 0.5s, background-color 0.5s;
}
.upperBtn:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(0,128,55,1);
}
.upperBtn:disabled{
    color:gray;
    background-color: #45a970;
}
.title{
    font-family: 'Comfortaa', 'sans-seriff';
    font-size: 3.5rem;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-bottom: 20px
}
.mainContainer{
    background-color: rgba(0,128,55,1);
    display: flex;
    flex-shrink: 0;
    gap: 40px;
    justify-content: center;
    min-height: 800px;
    max-width: 1200px;
    overflow: hidden;
    border-radius: 30px;
    padding: 50px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.elementsContainer{
    width: 530px;
    height: 750px;
}
.imageContainer{
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.loaderContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.loader{
    font-size: 4rem;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.text{
    font-family: 'Oswald', 'sans-serif';
}
.bottomBtnContainer{
    display: none;
}
@media (max-width: 1350px) {
    .mainContainer{
        min-height: 700px;
        max-width: 100vw;
    }
    .bottomBtnContainer{
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 3px;
        margin: 20px 0;
    }
    .bottomBtn{
        padding: 3px 10px;
        font-size: 1.2rem;
        border-radius: 15px;
        color: #45a970;
        background-color: black;
        border: 1px solid transparent; 
        transition: box-shadow 0.5s, background-color 0.5s;
    }
    .bottomBtn:hover{
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.5);
        background-color: rgba(0,128,55,1);
    }
    .bottomBtn:disabled{
        color:gray;
        background-color: #45a970;
    }
    .upperBtn{
        display: none;
    }
}
@media (max-width: 1180px) {
    .mainContainer{
        flex-direction: column;
        width: 60vw;
        padding: 20px;
        border-radius: 20px;
    }
}
@media (max-width: 900px) {
    .mainContainer{
        width: 80vw;
    }
    .elementsContainer{
        width: 480px;
        height: 680px;
    }
}
@media (max-width: 800px) {
    .mainContainer{
        width: 90vw;
    }
    .elementsContainer{
        width: 400px;
        height: 580px;
    }
}

@media (max-width: 450px) {
    .mainContainer{
        width: 100vw;
    }
    .elementsContainer{
        width: 350px;
        height: 480px;
    }
}