.OrderSection{
    background-color: black;
    padding: 2rem;
    font-family: 'Oswald', sans-serif;
}

.order{
    width: 60%;
    margin: 40px auto;
}

.txtEtc{
    color: white;
}

.title{
    color: #9f1312;
    font-family: 'BootsHaus-Regular';
    text-align: center;
    font-size: 4rem;
}

.tuple{
    display: flex;
    justify-content: space-between;
}

.part{
    display: flex;
    flex-direction: column;
    width: 48%;
}

.createSubmitBtn{
    margin-top: 10px;
    all: unset;
    font-size: 2rem;
    font-family: 'BootsHaus-Regular';
    font-weight: 500;
    background-color: #9f1312;
    border: 2px solid #9f1312;
    color: black;
    border-radius: 10px;  
    padding: 4px 20px 0px 20px;
    transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
}

.createSubmitBtn:hover{
    cursor: pointer;   
    background-color: black;
    border: 2px solid #9f1312;
    color: #9f1312;
    box-shadow:
              0 0 1rem rgba(159, 19, 18, 0.4),
              0 0 0.4rem rgba(159, 19, 18, 0.4),
              0 0 1.2rem rgba(159, 19, 18, 0.4);
}


@media(max-width: 1200px){
    .title{
        font-size: 3.5rem;
    }
    .order{
        width: 80%;
    }
}

@media(max-width: 768px){
    .order{
        width: 100%;
    }
}

@media(max-width: 730px){
    .tuple{
        display: block;
    }
    .part{
        display: block;
        width: 100%;
    }
}
