.bigContainer{
    padding: 40px 10px 80px 10px;
    width: 100%;
    background-color: black;
}
.mainContainer{
    margin: 0 auto;
    padding: 40px 10px;
    height: 100%;
    width: 80%;
    border-radius: 30px;
    background-color: black;
    border: 2px solid #9f1312;
    box-shadow:
              0 0 2rem #9f1312,
              0 0 0.8rem #9f1312,
              0 0 2.8rem #9f1312;
}
.imageContainer{
    margin: 0 auto 60px auto;
    max-width: 600px;
    overflow: hidden;
    border: 1px solid black;
    border-radius: 20px;
    box-shadow:
              0 0 2rem rgba(159, 19, 18, 0.4),
              0 0 0.8rem rgba(159, 19, 18, 0.4),
              0 0 2.8rem rgba(159, 19, 18, 0.4);
}
.txtsContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.txtContainer{
    padding: 25px;
    width: 28%;
    border-radius: 20px;
    box-shadow:
              0 0 1.2rem rgba(159, 19, 18, 0.4),
              0 0 0.8rem rgba(159, 19, 18, 0.4),
              0 0 1.8rem rgba(159, 19, 18, 0.4);
}
.subTitle{
    color: white;
    font-size: 3rem;
    font-family: 'BootsHaus-Regular';
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}
.desc{
    color: white;
    font-size: 1.6rem;
    font-family: 'Oswald', 'sans-serif';
}
.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media(max-width:1380px){
    .txtsContainer{
        margin: 0 auto;
        gap: 30px;
        width: 70%;
        align-items: center;
        flex-direction: column;
    }
    .txtContainer{
        width: 600px;
        box-shadow:
              0 0 0.6rem rgba(159, 19, 18, 0.4),
              0 0 0.8rem rgba(159, 19, 18, 0.4),
              0 0 1.2rem rgba(159, 19, 18, 0.4);
    }
}
@media(max-width: 820px){
    .mainContainer{
        padding: 20px 20px;
    }
    .txtsContainer{
        width: 100%;
    }
    .txtContainer{
        width: 100%;
    }
}
@media(max-width: 730px){
    .mainContainer{
        width: 95%;
    }
}