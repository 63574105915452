.cookieRules{
    color: black;
    background-color: #e8ffc7; 
}
.container{
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px 30px;
}
.title{
    font-family: 'Comfortaa', 'sans-serif';
    color: #008037;
    font-size: 3rem;
    margin-bottom: 10px;
}
.subtitle{
    font-family: 'Comfortaa', 'sans-serif';
    color: #008037;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 5px;
}
.rule{
    font-family: 'Comfortaa', 'sans-serif';
}
.link{
    text-decoration: none;
    color: #008037;
}
.link:hover{
    text-decoration: underline;
}