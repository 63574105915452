.bigContainer{
    padding: 40px 20px;
    width: 100%;
    background: rgb(106,20,20);
    background: linear-gradient(153deg, rgba(106,20,20,1) 0%, rgba(0,0,0,1) 50%, rgba(106,20,20,1) 100%); 
    min-height: 40vh;
    border-top: 1px solid #9f1312;
    border-bottom: 1px solid #9f1312;
}
.title{
    color: white;
    font-family: 'BootsHaus-Regular';
    font-size: 4rem;
    text-align: center;
    margin-bottom: 30px;
}
.cardsContainer{
    margin: 0 auto;
    width: 60%;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 30px;
    row-gap: 30px;
    justify-content: space-around;
}
.imgContainer{
    width: 250px;
    height: 390px;
    border: 1px solid #9f1312;
    border-radius: 20px;
    overflow: hidden;
    transition: transform 0.4s, border-radius 0.4s;
}
.imgContainer:hover{
    border-radius: 5px;
    transform: scale(1.6);
    z-index: 100;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media(max-width: 1400px) {
    .cardsContainer{
        width: 90%;
    }
}
@media(max-width: 930px){
    .cardsContainer{
        grid-template-columns: auto auto;
        justify-content: space-around;
    }
    .imgContainer:hover{
        transform: scale(1.2);
    }
}
@media(max-width: 650px){
    .cardsContainer{
        grid-template-columns: auto;
    }
    .imgContainer{
        width: 350px;
        height: 550px;
    }
    .imgContainer:hover{
        transform: none;
        border-radius: 20px;
    }
}