.alertDiv{
    background-color: #e8ffc7;
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 500px;
    align-items: center;
  }
  
  .responseImg{
    max-width: 620px;
    min-width: 200px;
  }
  
  .spinnerContainer{
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinnerInnerContainer{
    width: 70%;
    height: 70%;
  }
  
  .spinner{
    width: 100%;
    height: 100%;
    font-size: 3rem;
    color: #008037;
  }