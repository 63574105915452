.terms{
    color: white;
    background-color: black; 
}
.container{
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px 30px;
}
.title{
    font-family: 'BootsHaus-Regular';
    color: #9f1312;
    font-weight: 500;
    font-size: 4rem;
    margin-bottom: 10px;
}
.subtitle{
    font-family: 'BootsHaus-Regular';
    color: #9f1312;
    font-weight: 500;
    font-size: 2.5rem;
    margin-bottom: 5px;
}
.rule{
    font-family: 'Oswald', 'sans-serif';
    font-size: 1.25rem;
}