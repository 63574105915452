.personalInfoRules{
    color: white;
    background-color: black;
    min-height: 72.6vh; 
}
.container{
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px 30px;
}
.title{
    font-family: 'BootsHaus-Regular';
    color: #9f1312;
    font-size: 4rem;
    margin-bottom: 10px;
}
.subtitle{
    font-family: 'BootsHaus-Regular';
    color: #9f1312;
    font-size: 2.5rem;
    margin-bottom: 5px;
}
.rule{
    font-family: 'Oswald', 'sans-serif';
    font-size: 1.25rem;
}
.link{
    text-decoration: none;
    color: #9f1312;
}
.link:hover{
    text-decoration: underline;
}