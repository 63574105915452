.bigContainer{
    width: 100vw;
    min-height: 100vh;
    padding: 20px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgb(50, 8, 0) 0%, rgba(195,37,9,1) 20%, rgba(0,128,55,1) 40%, rgba(232,255,199,1) 60%, rgba(195,37,9,1) 80%, rgb(50, 8, 0) 100%); 
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
}
.smallContainer{
    flex: 1;
    flex-shrink: 1;
    text-decoration: none;
    max-height: 800px;
    max-width: 500px;
    border-radius: 50px;
    padding-bottom: 30px;
    padding-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.5s, box-shadow 0.5s;
}
.smallContainer:hover{
    transform: translateY(-15px);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 8px 24px 0 rgba(0, 0, 0, 0.3);
}
.smallContainer:focus{
    transform: translateY(-15px);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 8px 24px 0 rgba(0, 0, 0, 0.3);
}
.bookImgContainer{
    margin: 0 auto 25px auto;
    max-width: 450px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #f8b11a;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.therapyImgContainer{
    margin: 0 auto 25px auto;
    max-width: 450px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #008037;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cardsImgContainer{
    margin: 0 auto 25px auto;
    max-width: 450px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    border: 3px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.bookTitle{
    height: 115px;
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 15px;
}
.therapyTitle{
    height: 115px;
    font-family: 'Comfortaa', 'sans-serif';
    color: black;
    font-weight: 700;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 25px;
}
.cardsTitle{
    height: 115px;
    font-family: 'BootsHaus-Regular';
    color: black;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 15px;
}
.descContainer{
    display: flex;
    align-items: center;
    width: 50%;
}
.bookDesc{
    height: 83px;
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 20px;
}
.therapyDesc{
    height: 83px;
    font-family: 'Comfortaa', 'sans-serif';
    color: black;
    font-size: 1.35rem;
    text-align: center;
    margin-bottom: 0px;
}
.cardsDesc{
    height: 83px;
    font-family: 'Oswald', 'sans-serif';
    color: black;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 20px;
}
.buttonContainer{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.bookButton{
    margin: 0 auto;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    text-decoration: none;
    background-color: #212529;
    border-radius: 20px;
    padding: 5px 30px;
    color: #f8b11a;
    transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
}
.bookButton:hover{
    cursor: pointer;   
    background-color: #f8b11a;
    color: #212529;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.therapyButton{
    margin: 0 auto;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    text-decoration: none;
    text-align: center;
    /* background-color: #e8ffc7; */
    background-color: gray;
    border: none;
    border-radius: 20px;
    padding: 5px 30px;
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
}
/* .therapyButton:hover{
    cursor: pointer;   
    background-color: black;
    color: #008037;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
} */
 .therapyButton:hover{
    cursor: default;
}
.cardsButton{
    margin: 0 auto;
    font-family: 'BootsHaus-Regular';
    font-size: 2rem;
    text-decoration: none;
    background-color: black;
    border-radius: 20px;
    padding: 5px 30px 0px 30px;
    color: red;
    transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
}
.cardsButton:hover{
    cursor: pointer;   
    background-color: red;
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.textContainer{
    padding: 5px 10px
}
@media (max-width: 1600px) {
    .bookTitle{
        font-size: 2.5rem;
        margin-bottom: 0;
    }
    .therapyTitle{
        font-size: 2.5rem;
        margin-bottom: 0;
    }
    .cardsTitle{
        font-size: 2.5rem;
        margin-bottom: 0;
    }
    .bookDesc{
        font-size: 1.45rem;
    }
    .therapyDesc{
        font-size: 1.15rem;
    }
    .cardsDesc{
        font-size: 1.45rem;
    }
}
@media (max-width: 1150px) {
    .bigContainer{
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(195,37,9,1) 20%, rgba(0,128,55,1) 40%, rgba(232,255,199,1) 60%, rgba(195,37,9,1) 80%, rgba(0,0,0,1) 100%);
        flex-direction: column;
        gap: 40px;
    }
    .smallContainer{
        width: 70%;
    }
    .bookTitle{
        font-size: 3rem;
        margin-bottom: 25px;
    }
    .therapyTitle{
        font-size: 3rem;
        margin-bottom: 25px;
    }
    .cardsTitle{
        font-size: 3rem;
        margin-bottom: 25px;
    }
    .bookDesc{
        font-size: 1.35rem;
        margin-bottom: 0;
    }
    .therapyDesc{
        font-size: 1.15rem;
    }
    .cardsDesc{
        font-size: 1.35rem;
    }
}
@media (max-width:750px){
    .smallContainer{
        width: 100%;
        min-height: 800px;
    }
    .bigContainer{
        padding: 10px;
    }
}
@media (max-width:500px){
    .bigContainer{
        padding: 1px;
    }
    .bookImgContainer{
        width: 100%;
    }
    .therapyImgContainer{
        width: 100%;
    }
}