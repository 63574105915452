.bigContainer{
    width: 100%;
    min-height: 30vh;
    background-color: #e8ffc7;
    padding: 40px 5px 100px 5px;
}
.smallerContainer{
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(232,255,199,1) 0%, rgba(127, 219, 87, 1) 30%, rgba(127, 219, 87, 1) 60%, rgba(232,255,199,1) 100%);
    display: flex;
    gap: 50px;
    justify-content: center;
    min-height: 400px;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 30px;
    padding: 50px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.imageContainer{
    background-color: rgba(0,128,55,1);
    border: 2px solid #e8ffc7;
    border-radius: 30px;
    overflow: hidden;
    max-width: 600px;
    min-width: 500px;
    min-height: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sideText{
    font-family: 'Comfortaa', 'sans-seriff';
    font-size: 1.75rem;
    text-align: center;
    color: black;
}
.sideTitle{
    font-family: 'Comfortaa', 'sans-seriff';
    font-size: 3rem;
    text-align: center;
    color: black;
    font-weight: 700;
}

@media(max-width: 1400px){
    .smallerContainer{
        flex-direction: column;
    }
}
@media (max-width: 830px) {
    .imageContainer{
        width: 80%;
        min-width: 0;
        min-height: 0;
    }
    .smallerContainer{
        padding: 20px;
    }
}
@media (max-width: 580px) {
    .imageContainer{
        width: 95%;
    }
    .smallerContainer{
        padding: 10px;
    }
}