.alertDiv{
    background-color: black;
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 500px;
    align-items: center;
  }
  
  .responseImg{
    max-width: 600px;
    min-width: 200px;
  }
  
  .spinner{
    width: 300px;
    height: 300px;
    font-size: 3rem;
  }