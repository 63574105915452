.header{
    /* background: linear-gradient(135deg, #000000, #43160f, #7d2516, #d04d26); */
    margin: 0 auto;
    min-height: 80vh;
    width: 80%;
    border: 2px solid #f8b11a;
    box-shadow:
              0 0 1rem rgba(248, 177, 26, 0.6),
              0 0 0.4rem rgba(248, 177, 26, 0.6),
              0 0 1.2rem rgba(248, 177, 26, 0.6);
    border-radius: 20px;
    background-color: black;
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 15px;
}
.title{
    text-align: center;
    font-family: 'Oswald', sans-serif;
    color: #f8b11a;
    font-size: 4.3rem;
    margin-bottom: 35px;
}
.descContainer{
    width: 40%;
}
.mainImageContainer{
    /* width: 40%;
    height: 95%; */
    /* border: 1px solid rgb(200, 66, 45); */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2); */
    /* box-shadow:
              0 0 1rem rgba(49, 16, 11, 0.911),
              0 0 0.4rem rgba(49, 16, 11, 0.911),
              0 0 1.2rem rgba(49, 16, 11, 0.911); */
    width: 480px;
    height: 750px;
    border-radius: 10px;
    overflow: hidden;
}
.mainImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.subtitleContainer{
    display: flex;
    justify-content: center;
}
.subtitle{
    text-align: center;
    font-family: 'Oswald', sans-serif;
    margin-top: 5px;
    font-size: 2.5rem;
    color: #f8b11a;
}
.desc{
    font-family: 'Oswald', sans-serif;
    margin-top: 0;
    margin-bottom: 2px;
    color: white;
    font-weight: 400;
    font-size: 2.5rem;
    text-align: center;
}
.explore{
    margin-top: 35px;
}

@media (max-width: 1575px){
    .title{
        font-size: 3.8rem;
    }
    .desc{
        font-size: 2.2rem;
    }
}
@media (max-width: 1125px) {
    .header{
        flex-direction: column-reverse;
        height: unset;
        padding: 30px 10px;
        gap: 10px;
    }
    .mainImageContainer{
        width: 550px;
        height: 550px;
    }
    .descContainer{
        text-align: center;
        width: 80%;
    }
}
@media (max-width: 595px) {
    .header{
        width: 100%;
        
    }
    .mainImageContainer{
        width: 100%;
        height: 450px;
    }
    .title{
        font-size: 3rem;
    }
    .desc{
        font-size: 1.5rem;
    }
    .subtitle{
        font-size: 2rem;
    }
}