
.container{
    padding: 50px 0;
    background-color: black;
    padding: 50px 0 50px 0;
}
.title{
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 40px;
}
.about{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin: 0 auto 50px auto;
    justify-content: center;
    align-items: center;
    width: 65%;
}
.aboutDescContainer{
    display: flex;
    width: 50%;
}
.aboutDesc{
    font-family: 'Oswald', 'sans-serif';
    color: white;
    font-size: 1.35rem;
    text-align: center;
}
.list li::marker{
    color:#f8b11a;
}
.aboutImgContainer{
    width: 50%;
    height: 90%;
    border-radius: 20px;
    overflow: hidden;
}
.aboutImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 1370px){
    .about{
        width: 80%;
    }
}
@media(max-width: 960px){
    .about{
        flex-direction: column;
        align-items: center;
    }
    .aboutDescContainer{
        width: 100%;
    }
    .aboutImgContainer{
        width: 100%;
    }
}