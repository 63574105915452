
.content{
    padding: 100px 0;
    color: white;
    min-height: 1000px;


    background-attachment: fixed;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    background-color: grey;
    background-blend-mode: multiply;

    background-image: url(../../../../public/images/Wisdom-From-The-Ashes/ContentBack.webp);
}

.title{
    font-family: 'Oswald', 'sans-serif';
    color: #f8b11a;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 40px;
}

.description{
    font-family: 'Oswald', 'sans-serif';
    text-align: justify;

    padding: 3rem;
    background-color: rgb(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 40px;

    width: 70%;
    margin: 0 auto;
    color: white;
    font-size: 1.9rem;
    margin-bottom: 50px;
}

.pageNumber{
    text-align: end;
}

.number{
    color: #f8b11a;
    margin-top: 10px;
}

.page{
    text-align: center;
    min-height: 1200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Ensures smooth scrolling on iOS */
}

.paginationContainer{
    display: flex;
    justify-content: center;
}

.pagination{
    display: flex;
    gap: 0;
    border-radius: 10px;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
}

.paginationItem{
    display: inline-block;
    padding: 20px;
    font-size: 1.2rem;
    font-family: 'Oswald', 'sans-serif';
    background-color: rgb(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    color: white;
    border-color: #f8b11a;
    transition: background-color 0.4s, color 0.4s, backdrop-filter 0.4s;
}

.paginationItem:hover{
    background-color: rgb(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    color: black;
    cursor: pointer;
    border-color: #f8b11a;
}

.paginationItemActive {
    display: inline-block;
    padding: 20px;
    font-size: 1.2rem;
    font-family: 'Oswald', 'sans-serif';
    background-color: rgb(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    overflow: hidden;
    color: #f8b11a;
}

.pageExample{
    padding: 2rem;
    background-color: rgb(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 40px;
    font-family: 'Oswald', 'sans-serif';
    text-align: center;
    font-size: 1.9rem;
    max-width: 1200px;
    margin: 0 auto 50px auto;
}


@media(max-width: 760px){
    .carousel{
        width: 100%;
    }
    .pageImg{
        width: 80%;
    }
    .title{
        margin: 0 auto;
        width: 90%;
        margin-bottom: 40px;
    }
    .description{
        text-align: start; 
        width: 100%;
    } 
}

@media(max-width: 520px){
    .pageImg{
        width: 100%;
    } 
    .title{
        font-size: 3rem;
    }
    .description{
        font-size: 1.5rem;
        text-align: center;
    }
    .pageExample{
        font-size: 1.5rem;
    }
}